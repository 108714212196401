@import '../../../setup/core';

.c-field-radio {
  @include useType(base);

  display: block;
  color: color(black);

  label {
    position: relative;
    display: flex;
    
    &:before {
      content: '';
      display: block;
      width: spacing(4);
      min-width: spacing(4);
      max-width: spacing(4);
      height: spacing(4);
      margin-right: spacing(narrow);
      background: color(white);
      border: 1px solid color(grey);
      border-radius: 50%;
    }
  }
  
  input {
    position: absolute;
    visibility: hidden;
  }

  .input {
    display: inline-block;
    position: relative;
    border: 1px dashed red;
  }

  .label {
    @include useSize(s);
    
    display: inline-block;
    margin-bottom: spacing(1);
    line-height: lineHeight(base);

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      width: spacing(3);
      height: spacing(3);
      background: color(blue);
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.5);
      transition: opacity .25, transform .25;
    }
  }

  input:checked + .label {
    &:after {
      transform: none;
      opacity: 1;
    }
  }
  
  .error {
    @include useSize(s);
    
    margin-top: spacing(1);
    color: color(red);
  }

  @include variant(is-error) {
    label {
      &:before {
        border-color: color(red);
      }
    }
  }

  @include variant(mobile-centered) {
    .label,
    textarea {
      text-align: center;
    }
  }

  @include variant(is-disabled) {
    pointer-events: none;
    
    label {
      opacity: .3;
    }
  }
}