@import '../../setup/core';

.c-shortcut {
  @include useType(base);
  padding: 20px 16px 4px;
  border-radius: 8px;
  width: 120px;
  height: 110px;
  padding: 15px;
  text-align: center;
  background-color: #FFF;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: #ededee;
  }

  .wrapper {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    height: 80px;
    width: 80px;
    display: flex;
    background-color: #FFF;
    margin: 0 auto;
  }

  .icon {
    font-size: 22px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1ab7ea;
  }

  .title {
    margin-top: 10px;
    color: #42526e;
    font-size: 12px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

}