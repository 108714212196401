// Font tools
@function font($font) {
  @return map-get($fonts, $font);
}

@mixin useFont($font) {
  font-family: font($font);
}


// Weight tools
@function weight($weight) {
  @return map-get($weights, $weight);
}

@mixin useWeight($weight) {
  font-weight: weight($weight);
}


// Line height tools
@function lineHeight($lineHeight) {
  @return map-get($lineHeights, $lineHeight);
}

@mixin useLineHeight($lineHeight) {
  line-height: lineHeight($lineHeight);
}


// Letter spacing tools
@function letterSpacing($letterSpacing) {
  @return map-get($letterSpacings, $letterSpacing);
}

@mixin useLetterSpacing($letterSpacing) {
  line-height: letterSpacing($letterSpacing);
}


// Size tools
@function size($set, $breakpoint) {
  $sizeSet: map-get($sizes, $set);
  @return map-get($sizeSet, $breakpoint);
}

@mixin useSize($size) {
  $set: map-get($sizes, $size);
  
  @each $breakpoint, $size in $set {
    @if $breakpoint == 'small' {
      font-size: map-get($set, 'small');
    }
    
    @else if $breakpoint == 'large' {
      @include on(large) {
        font-size: $size;
      }
    }
    
    @else {
      @include from($breakpoint) {
        font-size: $size;
      }
    }
  }
}

// Selective size function
// Fetches the value from a specific set+breakpoint combo
@function size($size, $breakpoint) {
  $set: map-get($sizes, $size);
  @return map-get($set, $breakpoint);
}


// Typography sets
@mixin useType($type, $size:false) {
  @include expand(map-get($typeSets, $type));
  
  @if $size {
    @include useSize($size);
  }
}