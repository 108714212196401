@import '../../../setup/core';

.c-field-title-text {
  @include useType(base);

  display: block;
  margin-bottom: spacing(wide);
  color: color(black);

  .display {
    @include useSize(giant);

    > * {
      cursor: pointer;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-left: spacing(narrow);
  }

  label {
    display: none;
  }
  
  input {
    @include useType(base, base);

    width: 100%;
    border: 1px solid color(grey);
    height: 40px;
    padding: 0 spacing(narrow);
    box-sizing: border-box;
    border-radius: 5px;
    transition: border-color .25s, box-shadow .25s;

    &:focus {
      outline: none;
      border-color: color(blue);
      box-shadow: 0 0 5px 5px rgba(color(blue), .1)
    }
  }
  
  .error {
    @include useSize(s);
    
    margin-top: spacing(1);
    text-align: right;
    color: color(red);
  }

  @include variant(is-error) {
    input {
      border-color: color(red);
    }
  }

  @include variant(mobile-centered) {
    .label,
    input {
      text-align: center;
    }
  }

  @include variant(password) {
    input {
      letter-spacing: .5em;
    }
  }

  @include variant(is-disabled) {
    pointer-events: none;
    
    input {
      opacity: .3;
    }
  }

  @include variant(open) {
    .display {
      display: none;
    }

    label {
      display: block;
    }
  }
}
