@import '../../../setup/core';

.c-field-checkbox {
  @include useType(base);

  display: block;
  color: color(black);

  .c-field-select & {
    margin-left: 10px;
  }

  label {
    position: relative;
    display: flex;
    
    &:before {
      content: '';
      display: block;
      width: spacing(4);
      min-width: spacing(4);
      max-width: spacing(4);
      height: spacing(4);
      margin-right: spacing(narrow);
      background: color(white);
      border: 1px solid color(grey);
    }
  }
  
  input {
    position: absolute;
    opacity: 0;
  }

  .label {
    @include useSize(s);
    
    display: inline-block !important;
    margin-bottom: spacing(1);
    line-height: lineHeight(base);
    cursor: pointer;

    &:after {
      content: url(data:image/svg+xml,%3Csvg%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%2016%2013%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xml%3Aspace%3D%22preserve%22%20style%3D%22fill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bstroke-linejoin%3Around%3Bstroke-miterlimit%3A2%3B%22%3E%0A%20%20%20%20%3Cg%20transform%3D%22matrix%281%2C0%2C0%2C1%2C-4360.54%2C-3883.83%29%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20transform%3D%22matrix%281%2C0%2C0%2C1%2C3563.26%2C3779.68%29%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%20transform%3D%22matrix%280.666667%2C0%2C0%2C0.666667%2C797.272%2C102.815%29%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M20.285%2C2L9%2C13.567L3.714%2C8.556L0%2C12.272L9%2C21L24%2C5.715L20.285%2C2Z%22%20style%3D%22fill%3Argb%2826%2C183%2C234%29%3Bfill-rule%3Anonzero%3B%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E);
      display: block;
      position: absolute;
      top: 0px;
      left: 3px;
      width: 17px;
      height: 14px;
      opacity: 0;
      transform: scale(1.5);
      transition: opacity .25, transform .25;
    }
  }

  input:checked + .label {
    &:after {
      transform: none;
      opacity: 1;
    }
  }
  
  .error {
    @include useSize(s);
    
    margin-top: spacing(1);
    color: color(red);
  }

  @include variant(is-error) {
    label {
      &:before {
        border-color: color(red);
      }
    }
  }

  @include variant(mobile-centered) {
    .label,
    textarea {
      text-align: center;
    }
  }

  @include variant(is-disabled) {
    pointer-events: none;
    
    label {
      opacity: .3;
    }
  }
}