@import '../../../setup/core';

.c-field-text {
  @include useType(base);

  display: block;
  color: color(black);

  .label {
    @include useSize(s);

    display: block;
    margin-bottom: spacing(1);
    font-weight: weight(semibold);

    &:empty {
      display: none;
    }
  }

  input {
    @include useType(base, base);

    width: 100%;
    border: 1px solid color(grey);
    height: 40px;
    padding: 0 spacing(narrow);
    box-sizing: border-box;
    border-radius: 5px;
    transition: border-color .25s, box-shadow .25s;

    &:focus {
      outline: none;
      border-color: color(blue);
      box-shadow: 0 0 5px 5px rgba(color(blue), .1)
    }

    .c-list-header[data-no-fit] & {

      &[type=text],
      &[type=date] {
        width: 160px !important;
      }
    }
  }

  .error {
    @include useSize(s);

    margin-top: spacing(1);
    text-align: right;
    color: color(red);
  }

  @include variant(is-error) {
    input {
      border-color: color(red);
    }
  }

  @include variant(mobile-centered) {

    .label,
    input {
      text-align: center;
    }
  }

  @include variant(password) {
    input {
      letter-spacing: .5em;
    }
  }

  @include variant(is-disabled) {
    pointer-events: none;

    input {
      opacity: .3;
    }
  }

  @include variant(mini) {
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .label,
    input {
      @include useSize(s);
      display: inline-block;
      box-sizing: border-box;
      //width: auto;
      white-space: nowrap;
      margin-bottom: 0;
    }

    input {
      width: 160px !important;
      font-weight: weight(semibold);
      height: 28px;
      background-color: rgba(255, 255, 255, .6);
      margin-left: spacing(narrow);
    }
  }

  @include variant(full-width) {
    display: block;
    width: 100%;

    input {
      width: 100%;
    }

    .label {
      &:empty {
        display: none;
      }
    }
  }
}