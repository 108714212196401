@import '../../../setup/core';

.c-checkbox-list {
  display: block;
  border-top: 1px solid color(grey, light);
  padding-top: spacing(2);

  .row {
    border-bottom: 1px solid color(grey, light);
    margin-bottom: spacing(2);
  }
}