@import '../../../setup/core';

.c-field-select {
  @include useType(base);

  display: inline-block;
  color: color(black);
  max-width: 100%;
  width: 100%;

  .label {
    @include useSize(s);

    display: block;
    margin-bottom: spacing(1);
    font-weight: weight(semibold);

    &:empty {
      display: none
    }
  }

  select {
    @include useType(base, base);

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    max-width: 100%;
    width: 100%;
    border: 1px solid color(grey);
    height: 40px;
    padding: 0 spacing(wide) 0 spacing(narrow);
    box-sizing: border-box;
    border-radius: 5px;
    transition: border-color .25s, box-shadow .25s;
    text-overflow: ellipsis;
    overflow: hidden;
    background-size: 8px 5px;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 6 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;'%3E%3Cpath d='M0.75,0.75l2.25,2.25l2.25,-2.25' style='fill:none;stroke:%23000;stroke-width:1.5px;'/%3E%3C/svg%3E");

    &:after {
      width: 8px;
      height: 5px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:focus {
      outline: none;
      border-color: color(blue);
      box-shadow: 0 0 5px 5px rgba(color(blue), .1)
    }
  }

  .c-list-header[data-no-fit] & {
    width: 100%;

    select,
    .c-field-checkbox {
      width: 160px !important;
    }
  }

  .error {
    @include useSize(s);

    margin-top: spacing(1);
    text-align: right;
    color: color(red);
  }

  @include variant(is-error) {
    select {
      border-color: color(red);
    }
  }

  @include variant(full-width) {
    display: block;

    select {
      width: 100%;
    }
  }

  @include variant(is-disabled) {
    pointer-events: none;

    select {
      opacity: .3;
    }
  }

  @include variant(mini) {
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .label,
    select {
      @include useSize(s);

      height: initial;
      font-weight: weight(semibold);
      min-width: 100px;
      max-width: 100%;

      &:focus {
        outline: none;
        border-color: none;
        box-shadow: none;
      }
    }

    select {
      font-weight: weight(semibold);
      height: 28px;
      background-color: rgba(255, 255, 255, .6);
      margin-left: spacing(narrow);
      padding: 0 spacing(6) 0 spacing(2);
    }
  }
}