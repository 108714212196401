@import '../../../setup/core';

.c-layout {
  display: block;
  min-height: 100vh;

  $header-height-mobile: 60px;

  .modals,
  .overlay,
  .menu {
    position: fixed;
    top: 0;
    left: 0;
  }

  .overlay {
    z-index: 1001;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;
  }

  .modals {
    z-index: 1002;
  }

  .submenu {
    background: color(grey, lightest);
    transition: width .5s, transform .5s;
    position: relative;

    .collapse-bar {
      position: absolute;
      width: 24px;
      height: 24px;
      right: -10px;
      top: 80px;
      background-color: #FFF;
      border-radius: 50%;
      cursor: pointer;
      display: table;
      z-index: 9999;
      box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;

      &:before {
        content: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' fill='rgb(107, 119, 140);' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8' height='8' x='0px' y='0px' viewBox='0 0 487 487' style='enable-background:new 0 0 487 487;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M397.7,376.1c20.4,20.4,53.6,20.4,74,0s20.4-53.6,0-74L280.5,110.9c-20.4-20.4-53.6-20.4-74,0L15.3,302.1 c-20.4,20.4-20.4,53.6,0,74s53.6,20.4,74,0l154.2-154.2L397.7,376.1z'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
        position: absolute;
        top: 4px;
        left: 6px;
        transform: rotate(-90deg);
      }

      &:hover {
        background-color: #1ab7ea;
      }

      &:hover:before {
        content: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' fill='white' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8' height='8' x='0px' y='0px' viewBox='0 0 487 487' style='enable-background:new 0 0 487 487;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M397.7,376.1c20.4,20.4,53.6,20.4,74,0s20.4-53.6,0-74L280.5,110.9c-20.4-20.4-53.6-20.4-74,0L15.3,302.1 c-20.4,20.4-20.4,53.6,0,74s53.6,20.4,74,0l154.2-154.2L397.7,376.1z'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");

      }

    }


    @include variant(menu-open, true) {
      width: 0px;
      transform: none;

      .collapse-bar:before {
        left: 10px;
        transform: rotate(90deg);

      }

      .c-sub-menu-item::before {
        width: 0;
      }

    }

  }



  .body {
    padding: spacing(base);

    >.contain {
      box-shadow: inset 0 0 5px #00000010;
    }
  }

  .sidebar {
    background: color(grey, lightest);
    padding: spacing(base);

    &:empty {
      background: transparent;
    }
  }

  .mid-wrapper {
    >.header {
      box-sizing: border-box;
      padding: spacing(base);
      border-bottom: 1px solid color(grey, light);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .table-end {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;

    .left,
    .right {
      flex-grow: 1;
    }
  }

  .dashboard {
    position: relative;

    .block {
      margin: 1em 0 3em;
    }

    .sticky {
      position: sticky;
      bottom: 0;
    }

    @include until(tablet) {
      margin-top: 80px;
      flex-direction: column-reverse;
    }

    @include from(tablet) {
      align-items: stretch;
    }
  }

  .advanced-search {
    background: color(grey, lightest);
    padding: spacing(narrow);
    padding-top: spacing(base);
    margin-bottom: spacing(base);
    position: sticky;
    left: 0;

    .title {
      @include useSize(l);

      text-align: center;
      line-height: lineHeight(base);
      font-weight: weight(semibold);
      padding: spacing(base) 0 spacing(wide);
    }
  }

  @include on(small) {
    .body {
      padding-top: spacing(20);
    }

    .submenu+.mid-wrapper .body {
      padding-top: spacing(base);
    }

    .menu {
      z-index: 111;
    }

    .mid-wrapper {
      >.header {
        position: fixed;
        z-index: 110;
        top: 0;
        width: 100%;
        background: color(white);
        height: $header-height-mobile;
      }
    }

    .profile {
      position: absolute;
      top: spacing(narrow);
      right: spacing(base);
    }
  }

  @include on(large) {
    height: 100vh;

    .outer-wrapper {
      display: flex;
      height: 100%;
      align-items: stretch;
    }

    .menu {
      position: relative;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .submenu {
      width: 250px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .mid-wrapper,
    .body,
    .sidebar {
      flex: 1;
      width: 50%;
    }

    .body:only-child {
      width: 100%;
    }

    .body.list {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: spacing(base);
    }

    .mid-wrapper {
      display: flex;
      flex-direction: column;
      overflow: scroll;

      >.header {
        flex-grow: 0;
        flex-shrink: 0;
        position: sticky;
        top: 0;
        z-index: 5;
        justify-content: space-between;
        background: color(white);
        padding: spacing(base) spacing(wide);
      }
    }

    .inner-wrapper {
      flex: 1;
      display: flex;
    }

    .body,
    .sidebar {
      padding: spacing(wide);
      box-sizing: border-box;
    }
  }

  @include variant(is-overlay-on) {
    .overlay {
      opacity: 1;
    }
  }
}