@mixin on($size) {
  @if $size == small {
    @media (max-width: map-get($breakpoints, layout-change) - 1px) {
      @content;
    }
  } @else if $size == large {
    @media (min-width: map-get($breakpoints, layout-change)) {
      @content;
    }
  }
}

@mixin from($bp) {
  @media (min-width: map-get($breakpoints, $bp)) {
    @content;
  }
}

@mixin until($bp) {
  @media (max-width: map-get($breakpoints, $bp) - 1px) {
    @content;
  }
}

@mixin between($from, $to) {
  @include from($from) {
    @include until($to) {
      @content;
    }
  }
}