@import '../../setup/core';

.c-button {
  @include useType(base, base);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0 spacing(base);
  border-radius: 5px;
  font-weight: weight(medium);
  cursor: pointer;
  transition: color .2s, background-color .2s, border-color .2s;
  box-sizing: border-box;

  img {
    width: 15px;
    height: 15px;
    margin-right: spacing(narrow);
  }

  @include variant(variant, primary) {
    background: color(blue);
    color: color(white);
    text-shadow: 0 2px rgba(0, 0, 0, .2);

    &:hover {
      background-color: darken(color(blue), 5);
    }

    @include variant(size, small) {
      @include useSize(s);

      height: 28px;
      border-width: 1px;
      padding: 0 spacing(narrow);
    }

    @include variant(color, red) {
      background: color(red);

      &:hover {
        background-color: darken(color(red), 5);
      }
    }

    @include variant(color, green) {
      background: color(green);

      &:hover {
        background-color: darken(color(green), 5);
      }
    }

    @include variant(color, grey) {
      background: color(grey, dark);

      &:hover {
        background-color: darken(color(grey, dark), 5);
      }
    }
  }

  @include variant(variant, secondary) {
    background: color(white);
    border: 2px solid color(blue);
    color: color(blue);

    &:hover {
      color: darken(color(blue), 5);
      border-color: darken(color(blue), 5);
    }

    @include variant(size, small) {
      @include useSize(s);

      height: 28px;
      border-width: 1px;
      padding: 0 spacing(narrow);
    }

    @include variant(color, red) {
      border-color: color(red);
      color: color(red);

      &:hover {
        color: darken(color(red), 5);
        border-color: darken(color(red), 5);
      }
    }

    @include variant(color, green) {
      border-color: color(green);
      color: color(green);

      &:hover {
        color: darken(color(green), 5);
        border-color: darken(color(green), 5);
      }
    }

    @include variant(color, grey) {
      border-color: color(grey, dark);
      color: color(grey, dark);

      &:hover {
        color: darken(color(grey, dark), 5);
        border-color: darken(color(grey, dark), 5);
      }
    }
  }

  @include variant(size, full) {
    display: flex;
  }

  @include variant(mobile-icon-only) {
    @include on(small) {
      padding: 0 spacing(narrow);

      img {
        margin-right: 0;
      }

      .label {
        display: none;
      }
    }
  }

  @include variant(disabled, true) {
    pointer-events: none;
    opacity: .5;
  }

  @include variant(variant, simple) {
    padding: 0;
    color: color(blue);
    text-decoration: underline;

    @include variant(size, small) {
      @include useSize(s);
    }

    @include variant(color, red) {
      color: color(red);
    }

    @include variant(color, green) {
      color: color(green);
    }

    @include variant(color, grey) {
      color: color(grey);
    }

    @include variant(color, black) {
      color: color(black);
    }
  }

  @include variant(no-underline) {
    text-decoration: none;
  }
}