html,
body {
  height: 100%;
}

body {
  @include useType(base, base);

  color: color(black);
  overflow-x: hidden;
  font-weight: weight(regular);
}

a {
  color: inherit;
  text-decoration: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: font(secondary);
}

hr {
  height: 2px;
  border: 0 none;
  background-color: color(grey, light);
  margin-bottom: spacing(base);

  &.spacer {
    background-color: transparent;
    margin: spacing(base) 0;
  }
}

.nowrap {
  white-space: nowrap;
}

.hidden {
  display: none;
}

.hidden-on-mobile {
  @include on(small) {
    display: none;
  }
}

.hidden-on-desktop {
  @include on(large) {
    display: none;
  }
}

button {
  outline: none;
  border: unset;
  background: unset;
  text-align: unset;
  color: unset;
  font-family: unset;
  padding: unset;
}

.dev-placeholder {
  font-family: font(primary);
  background: DarkMagenta;
  border: 5px solid white;
  padding: 25px;
  color: white;
  text-align: center;
}

.centered {
  text-align: center;
}

.justify-right {
  text-align: right;
}

.row {
  margin-bottom: spacing(base);

  &.wide {
    margin-top: spacing(wide);
  }

  &.line {
    margin-bottom: spacing(narrow);
    padding-bottom: spacing(narrow);
    border-bottom: 1px solid color(grey);
  }

  &.right {
    text-align: right;
  }
}

.inline-spacer {
  display: inline-block;
  width: spacing(base);
}

.hidden-desktop {
  @include on(large) {
    display: none;
  }
}

.hidden-mobile {
  @include on(small) {
    display: none;
  }
}

.contain {
  contain: content;
}

.full-height {
  flex: 1 1 1px;
  overflow: auto;
}

.no-margin {
  margin: 0
}