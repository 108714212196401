.html-content {
  @include useType(base, s);

  line-height: lineHeight(base);

  h1 {
    @include useType(title, title-xl);

    margin-bottom: spacing(base);

    @include on(large) {
      margin-bottom: spacing(wide);
    }
  }

  h2 {
    font-weight: weight(semibold);
    margin-top: spacing(wide);
    margin-bottom: spacing(base);
    text-align: center;
  }

  h3 {
    @include useType(base, large);

    font-weight: weight(semibold);
    margin-bottom: spacing(narrow);
    margin-top: spacing(base);
  }

  h4 {
    font-weight: weight(semibold);
  }

  a {
    text-decoration: underline;
    color: color(blue);
  }

  b,
  strong {
    font-weight: weight(bold);
  }

  i,
  em {
    font-style: italic;
  }

  p {
    margin-bottom: spacing(base);
  }

  ul,
  ol {
    padding-left: spacing(wide);
    margin-bottom: spacing(base);
  }

  li {
    margin-bottom: spacing(narrow);
  }

  ul {
    list-style: none;

    li {
      position: relative;
      padding-left: spacing(3);
    }
  }

  ol {
    counter-reset: item;

    li {
      position: relative;
      padding-left: spacing(3);
      list-style-type: none;
      counter-increment: item;

      &:before {
        content: counter(item) ".";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        font-weight: bold;
      }
    }
  }

  blockquote {
    border-left: 1px solid color(line);
    padding: spacing(base) spacing(base) spacing(narrow) spacing(base);
    font-style: italic;
    margin-bottom: spacing(base);
  }

  > *:first-child {
    margin-top: 0 !important;
  }

  table {
    margin-bottom: spacing(base);
    width: 100%;
  }

  table,
  td,
  th {
    border: 1px solid color(line);
    padding: spacing(narrow);
    text-align: left;

    p:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  th {
    font-weight: weight(bold);
  }

  .emoji {
    display: inline-block;
    height: 1.2em;
    width: 1.2em;
    margin-left: 0.075em;
    margin-right: 0.075em;
    vertical-align: -20%;
  }
  
  .c-button {
    display: inline-block;
    text-decoration: none;
    color: color(text, white);
    background: color(background, green);
  }
}