@mixin variant($name, $value: null) {
  @if $value {
    &[data-#{$name}="#{$value}"] {
      @content;
    }
  }
  @else {
    &[data-#{$name}] {
      @content;
    }
  }
}