@mixin context($name, $value: null) {
  @if $value {
    [data-#{$name}="#{$value}"] & {
      @content;
    }
  }
  @else {
    [data-#{$name}] & {
      @content;
    }
  }
}

@mixin directContext($name, $value: null) {
  @if $value {
    [data-#{$name}="#{$value}"] > & {
      @content;
    }
  }
  @else {
    [data-#{$name}] > & {
      @content;
    }
  }
}