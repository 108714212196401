////////////////////////
// GRID
////////////////////////

$grid-columns: 12;

// should be base + one for each main breakpoint
$grid-gutters: (
  base: spacing(narrow),
  tablet: spacing(narrow),
  tablet-horizontal: spacing(base),
  desktop: spacing(base),
);

$grid-gutters-wide: (
  base: spacing(base),
  tablet: spacing(base),
  tablet-horizontal: spacing(wide),
  desktop: spacing(wide),
);

$grid-gutters-narrow: (
  base: spacing(narrow),
  tablet: spacing(narrow),
  tablet-horizontal: spacing(narrow),
  desktop: spacing(narrow),
);

@mixin cellGutterCompensation($bp) {
  $v: map-get($grid-gutters, $bp);

  margin-left: -$v;
  margin-right: -$v;
}

@mixin cellGutterCompensationWide($bp) {
  $v: map-get($grid-gutters-wide, $bp);

  margin-left: -$v;
  margin-right: -$v;
}

.b-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  >a {
    display: contents;
  }

  @each $bp,
  $bpv in $grid-gutters {
    @if $bp==base {
      @include cellGutterCompensation($bp);
    }

    @else {
      @include from($bp) {
        @include cellGutterCompensation($bp);
      }
    }
  }

  @include variant(space, wide) {

    @each $bp,
    $bpv in $grid-gutters-wide {
      @if $bp==base {
        @include cellGutterCompensationWide($bp);
      }

      @else {
        @include from($bp) {
          @include cellGutterCompensationWide($bp);
        }
      }
    }
  }

  @include variant(multiline) {
    >* {
      margin-bottom: spacing(base);
    }
  }

  @include variant(center) {
    justify-content: center;
  }

  @include variant(vertical-center) {
    align-items: center;
  }

  @include variant(invert, tablet) {
    @include from(tablet) {
      flex-direction: row-reverse;
    }
  }

  @include variant(invert, tablet-horizontal) {
    @include from(tablet-horizontal) {
      flex-direction: row-reverse;
    }
  }

  @include variant(invert, desktop) {
    @include from(desktop) {
      flex-direction: row-reverse;
    }
  }

  @include variant(top) {
    align-items: flex-start;
  }

  @include variant(flex-start) {
    justify-content: flex-start;

  }

}





////////////////////////
// CELLS
////////////////////////

@mixin cellSize($size) {
  width: 100% / $grid-columns * $size;
}

@mixin offset($size) {
  margin-left: 100% / $grid-columns * $size;
}

@mixin cellGutters($bp) {
  $v: map-get($grid-gutters, $bp);

  padding-left: $v;
  padding-right: $v;

  [data-multiline]>& {
    padding-top: $v;
    padding-bottom: $v;
  }
}

@mixin cellGuttersWide($bp) {
  $v: map-get($grid-gutters-wide, $bp);

  padding-left: $v;
  padding-right: $v;
  padding-top: $v;
  padding-bottom: $v;
}

@mixin cellGuttersNarrow($bp) {
  $v: map-get($grid-gutters-narrow, $bp);

  padding-left: $v;
  padding-right: $v;
  padding-top: $v;
  padding-bottom: $v;
}

.b-cell {
  display: block;
  width: auto;
  min-width: 0;
  box-sizing: border-box;
  position: relative;

  @for $i from 1 through $grid-columns {
    @include variant('base', $i) {
      @include cellSize($i);
    }
  }

  @each $bp,
  $bpv in $breakpoints {
    @for $i from 1 through $grid-columns {
      @include variant($bp, $i) {
        @if $bp==base {
          @include cellSize($i);
        }

        @else {
          @include from($bp) {
            @include cellSize($i);
          }
        }
      }
    }
  }

  @for $i from 0 through $grid-columns {
    @include variant(offset, $i) {
      @include offset($i);
    }
  }

  @each $bp,
  $bpv in $breakpoints {
    @for $i from 0 through $grid-columns {
      @include variant(offset-#{$bp}, $i) {
        @if $bp==base {
          @include offset($i);
        }

        @else {
          @include from($bp) {
            @include offset($i);
          }
        }
      }
    }
  }

  @each $bp,
  $bpv in $grid-gutters {
    @if $bp==base {
      @include cellGutters($bp);
    }

    @else {
      @include from($bp) {
        @include cellGutters($bp);
      }
    }
  }

  @include variant(align, center) {
    text-align: center;
  }

  @include variant(align, right) {
    text-align: right;
  }

  @include variant(flex-end) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  @include variant(flex) {
    display: flex;
  }


  @include variant(flex-center) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include context(space, wide) {

    @each $bp,
    $bpv in $grid-gutters {
      @if $bp==base {
        @include cellGuttersWide($bp);
      }

      @else {
        @include from($bp) {
          @include cellGuttersWide($bp);
        }
      }
    }
  }

  @include context(space, narrow) {

    @each $bp,
    $bpv in $grid-gutters {
      @if $bp==base {
        @include cellGuttersNarrow($bp);
      }

      @else {
        @include from($bp) {
          @include cellGuttersNarrow($bp);
        }
      }
    }
  }

  @include context(space, none) {
    padding-left: 0;
    padding-right: 0;
  }
}